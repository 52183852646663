import React from "react"
import PropTypes from "prop-types"
import BaseLayout from './base'
import CurrentUserState from "../state/CurrentUserState"
import { Loader100vh } from '../components/UI/Loader'

const AuthenticatedLayout = ({ children }) => {
  const { loading, currentUser } = CurrentUserState.useContainer()

  if (loading) return <Loader100vh />
  return (
    <BaseLayout>
      { children }
    </BaseLayout>
  )
}

AuthenticatedLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthenticatedLayout

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import FirebaseState from "../state/FirebaseState"
import { colors } from "../theme"
import Helmet from 'react-helmet'
import { Loader100vh } from '../components/UI/Loader'

const Wrapper = styled.main`
  min-height: 100vh;
  background-image: linear-gradient(to left, ${colors.primary}, ${colors.secondary});
`

const Layout = ({ children }) => {
  const { firebase } = FirebaseState.useContainer()

  
  useEffect(() => {
    if (typeof window === 'undefined' || !firebase) return
    firebase.analytics().logEvent('screen_view', { screen_name: window.location.pathname });
  }, [])
  
  return (
    <>
      <Helmet title='Les Amoureux' />
      <Wrapper>
        { firebase ? children : <Loader100vh /> }
      </Wrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

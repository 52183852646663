import { useState, useEffect, useMemo } from "react"
import CurrentUserState from '../../../state/CurrentUserState'
import FirebaseState from '../../../state/FirebaseState'
import { createContainer } from "unstated-next"

const PAGE_SIZE = 10

const useMessageOfTheDay = () => {
  const { firebase, db } = FirebaseState.useContainer()
  const { currentUser } = CurrentUserState.useContainer()
  const [quote, setQuote] = useState([])
  const [myQuotes, setMyQuotes] = useState([])
  const [loadingCurrent, setLoadingCurrent] = useState(true)
  const [loadingMyQuotes, setLoadingMyQuotes] = useState(true)
  const [loadingAllQuotes, setLoadingAllQuotes] = useState(false)
  const [allQuotes, setAllQuotes] = useState([])
  const [hasMore, setHasMore] = useState(true)

  const quotes = db.collection("quotes")

  useEffect(() => {
    getCurrent()
    getNext()
  }, [])


  const getCurrent = async () => {
    const snapshot = await quotes
      .where("date", ">=", new Date(Date.now() - 24 * 3600 * 1000))
      .where("date", "<", new Date())
      .get()
    if (snapshot.docs.length === 0) return
    const filtered = snapshot.docs.map(d => d.data()).filter(d => d.createdBy !== currentUser.uid)
    setQuote(filtered && filtered[0])
    setLoadingCurrent(false)
  }
  
  const loadAllQuotes = async () => {
    setLoadingAllQuotes(true)
    const snapshot = await quotes
      .where("date", "<", new Date())
      .get()
    setAllQuotes(snapshot.docs.map(d => ({ id: d.id, ...d.data() })).sort((a, b) => b.date.seconds - a.date.seconds))
    setLoadingAllQuotes(false)
  }

  const query = useMemo(() => {
    const cursor = allQuotes[allQuotes.length - 1] && allQuotes[allQuotes.length - 1].date
    let query = quotes.orderBy('date', 'desc')
    if (allQuotes.length > 0) query = query.startAfter(cursor)
    return query.limit(PAGE_SIZE)
  }, [allQuotes])

  const loadMore = async (page) => {
    const snapshot = await query.get()
    if (snapshot.docs.length === 0) return setHasMore(false)
    setAllQuotes([...allQuotes, ...snapshot.docs.map(d => ({ id: d.id, ...d.data() }))])
  }

  const getNext = async () => {
    const snapshot = await quotes
      .where("seen", "==", false)
      .where("createdBy", "==", currentUser.uid)
      .get()
    setMyQuotes(snapshot.docs.map(d => ({ id: d.id, ...d.data() })))
    setLoadingMyQuotes(false)
  }

  const remove = async (quote) => {
    await quotes.doc(quote.id).delete()
    firebase.analytics().logEvent('remove_mdj');
    setMyQuotes([...myQuotes].filter(q => q.id !== quote.id))
  }

  const update = async (quote, payload) => {
    await quotes.doc(quote.id).update(payload)
    setMyQuotes([...myQuotes].map(q => q.id === quote.id ? {...q, ...payload} : {...q}))
    firebase.analytics().logEvent('update_mdj');
  }

  const add = async ({ date, value, author }) => {
    const docRef = await quotes.add({
      date,
      value,
      author,
      seen: false,
      createdBy: currentUser.uid,
      createdByRef: firebase.firestore().doc(`/users/${currentUser.uid}`)
    })
    firebase.analytics().logEvent('add_mdj');
    const doc = await docRef.get()
    setMyQuotes([...myQuotes, { id: doc.id, ...doc.data() }])
  }

  return { loadingCurrent, loadingMyQuotes, myQuotes, quote, add, update, remove, loadingAllQuotes, hasMore, loadMore, loadAllQuotes, allQuotes }
}

const MessageOfTheDayState = createContainer(useMessageOfTheDay)

export default MessageOfTheDayState

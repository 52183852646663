import React from 'react'
import styled from "styled-components"
import Div100vh from 'react-div-100vh'
import { colors } from '../../theme'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const Loader = styled.div`
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  background-color: #fff;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
::before, ::after {
  display: block;
  width: 50px;
  height: 50px;
  background-color: #fff;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}
::before, ::after {
  content: '';
  position: absolute;
  border-radius: 50%;
}
:before {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-animation: animX 3s infinite 0.75s;
          animation: animX 3s infinite 0.75s;
}
:after {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-animation: animY 3s infinite;
          animation: animY 3s infinite;
}
@-webkit-keyframes animX {
  0%, 25% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  50%, 75% {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
}
@keyframes animX {
  0%, 25% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  50%, 75% {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
}
@-webkit-keyframes animY {
  0%, 25% {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  50%, 75% {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}
@keyframes animY {
  0%, 25% {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  50%, 75% {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}
`

export default () => <Wrapper><Loader /></Wrapper>
export const Loader100vh = () => <Div100vh>
  <Wrapper>
    <Loader />
  </Wrapper>
</Div100vh>
import React from "react"
import PropTypes from "prop-types"
import AuthenticatedLayout from './authenticated'
import CurrentUserState from "../state/CurrentUserState"
import { navigate } from 'gatsby'
import { Loader100vh } from '../components/UI/Loader'
import MessageOfTheDayState from "../components/pages/MessageOfTheDay/index.state"

const PrivateLayout = ({ children }) => {
  const { loading, currentUser } = CurrentUserState.useContainer()

  if (loading) return <Loader100vh />
  if (!currentUser.admin) navigate('/')

  return (
    <AuthenticatedLayout>
      <MessageOfTheDayState.Provider>
        { children }
      </MessageOfTheDayState.Provider>
    </AuthenticatedLayout>
  )
}

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PrivateLayout
